<template>
  <div class="ocularOpticalTestingDetail">
    <div class="name">{{ detail.name || '' }}</div>
    <div class="information flexrowbetween">
      <div class="grade">
        {{ detail.grade || '' }} {{ detail.className || '' }}
      </div>
      <div class="date">筛查时间：{{ detail.reportTime || '' }}</div>
    </div>
    <table>
      <tr>
        <th style="width: 33.33%; padding: 12px 0; color: #9c9fad;">
          项目名称
        </th>
        <th style="width: 33.33%;">右眼</th>
        <th style="width: 33.33%;">左眼</th>
      </tr>
      <tr align="center">
        <td style="padding: 12px 0;">裸眼视力</td>
        <td>{{ detail.nakedEyeVisionRight || '-' }}</td>
        <td>{{ detail.nakedEyeVisionLeft || '-' }}</td>
      </tr>
      <tr align="center">
        <td style="padding: 12px 0;">戴镜视力</td>
        <td>{{ detail.eyewearVisionRight || '-' }}</td>
        <td>{{ detail.eyewearVisionLeft || '-' }}</td>
      </tr>
    </table>
    <div class="title">初筛结果</div>
    <div class="advice flexcolumnstart">
      <p>右眼：{{ detail.resultRight }}</p>
      <p class="mgtop30">左眼：{{ detail.resultLeft }}</p>
    </div>
    <div class="advices">
      <div class="standard">参考标准</div>
      <p class="mgbtm10">1.轻度视力不良：单眼裸眼视力 = 4.9</p>
      <p class="mgbtm10">2.中度视力不良：4.6 ≤ 单眼裸眼视力 ≤ 4.8</p>
      <p class="mgbtm20">3.重度视力不良：单眼裸眼视力 ≤ 4.5</p>
      <p class="mgbtm20">视力正常值：达到5.0（1.0）以上（6岁以上）。</p>
      <p class="mgbtm20">
        注：本报告仅起警示作用，不能作为处方依据，给检测者带来提示参考性建议，有疑义者建议到树兰（杭州）医院进行进一步检查或线上直接咨询树兰医院眼科专家。
      </p>
      <div class="mgbtm20 flexrowstart">
        <div>地址：</div><div class="flexcolumnstart" style="flex:1"><p>树兰（杭州）医院&nbsp;门诊2楼</p><p>杭州市拱墅区东新街道东新路848号（距离学校2公里内）</p></div>
      </div>
      <p class="mgbtm20">爱眼热线：<span class="phone" @click="callPhone('0571-56757074')">0571-56757074</span><span class="phones">;</span><span class="phone" @click="callPhone('0571-56757044')">0571-56757044</span></p>
    </div>
    <div class="btnBtom btnBg">
      <p @click="submit" class="activeBtn2">
        咨询眼科专家
      </p>
      <div class="g-safe-area-bottom"></div>
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid'
import getcollect from '../../../utils/databurying'
export default {
  data() {
    return {
      id: '',
      detail: {},
      currentpagepath: '',
      comfrompath: '',
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log(to, from, '页面')
    next((vm) => {
      vm.currentpagepath = to.fullPath
      vm.comfrompath = from.fullPath
    })
  },
  methods: {
    callPhone(phone){
      let tel = 'tel:'+phone
      window.location.href = tel;
    },
    submit() {
       //跳转小程序专家问诊
          this.openweixin()
      let url = '/pages/medical-package/alldoctor/alldoctor'
      let newUrl = encodeURIComponent(url)
      let params =
        'ufrom=ocularOptical&departmentFirstId=b24cf56a-6e34-4c7c-9760-121b630a137f&departmentSecondId=60590cdb-2fd7-4a75-a1d6-459c6e84a0f4'
      let newParams = encodeURIComponent(params)
      this.$axios
        .get(
          `/ms-pocket-hospital/v1/live/getScheme/live?path=${newUrl}&params=${newParams}`,
          { basurlType: 'useslhospital' },
        )
        .then((res) => {
          let address = res.data.results || ''
          // window.open(url)
           window.open(address,'_self');
        })
        .catch((err) => {
          console.log(err)
        })
    },
    //数据埋点
    openweixin() {
      let gid = uuidv4().replace(/-/g, '')
      let p = this.currentpagepath
      let t = 'click'
      let r = uuidv4().replace(/-/g, '')
      let v = '跳转健康咨询-眼科'
      let p0 = this.comfrompath
      getcollect({ gid, p, t, r, v, p0 })
    },
    getDetail() {
      this.$axios
        .get(`/EHR/organization/screen/detail?id=${this.id}`, {
          basurlType: 'commonurl',
        })
        .then((res) => {
          this.detail = res.data || {}
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    this.id = this.$route.query.id
    this.getDetail()
  },
}
</script>
<style lang="scss">
@import '../../styles/common.scss';
@import '../../styles/flexcommon.scss';
.ocularOpticalTestingDetail {
  display: flex;
  flex-direction: column;
  background: #f4f5f7;
  // height: 100vh;
  .name {
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #040814;
    padding: 48px 28px 18px;
  }
  .information {
    padding: 0 28px 31px;
  }
  .grade {
    font-size: 30px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #040814;
  }
  .date {
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9c9fad;
  }
  table {
    font-size: 30px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #040814;
    background: #ffffff;
  }
  table th {
    border: 1px solid #eef1f5;
  }
  table td {
    border: 1px solid #eef1f5;
  }
  .title {
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4f5265;
    padding: 50px 28px 22px;
  }
  .advice {
    padding: 40px 28px;
    background: #ffffff;
    font-size: 30px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #040814;
  }
  .mgtop30 {
    margin-top: 30px;
  }
  .advices {
    font-size: 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4f5265;
    padding: 60px 28px 250px;
  }
  .mgbtm10 {
    margin-bottom: 10px;
  }
  .mgbtm20 {
    margin-bottom: 20px;
  }
  .advices p {
    text-align: justify;
  }
  .standard {
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #040814;
    margin-bottom: 17px;
  }
  .phone{
    color:#12BEAE;
    border-bottom:2px solid #12BEAE;
  }
  .phones{
    width:20px;
    text-align: center;
    color:#12BEAE;
  }
  .pdleft{
    padding-left:80px
  }
}
</style>
